import Vue from 'vue'
import translations from '@/utils/i18n'
import p from '@/utils/pluralizer'

function getLang() {
  var cookies = document.cookie.split('; ')
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].split('=')
    if (cookie[0] === 'googtrans') {
      return decodeURIComponent(cookie[1]).replace('/en/', '')
    }
  }
  return null
}

function getTranslation (str: string, isAddSpecialSpaces = true): string | null {
  const lang = getLang()
  const translationList = translations()

  if (!lang) return str

  let translatedStr: string | null = null
  for (const translation of translationList) {
    if (
      translation.language === lang &&
      translation.original === str
    ) {
      translatedStr = translation.translated
      break
    }
  }

  if (translatedStr) {
    if (isAddSpecialSpaces) {
      translatedStr = translatedStr.replace(/ /g, '‎ ')
    }
    return `‎${translatedStr}‎`
  }

  return str
}

Vue.mixin({
  methods: {
    t(str, isAddSpecialSpaces = true) {
      return getTranslation(str, isAddSpecialSpaces)
    },
    getLang() {
      return getLang()
    },
    p(adventureTeam: any, word: string, count = 1, isLower = true, isPlural = false) {
      return p(adventureTeam, word, count, isLower, isPlural)
    }
  }
})
