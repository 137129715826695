export default function p(adventureTeam: any, word: string, count = 1, isLower = true, isPlural = false) {
  try {
    let rWord = word

    if (count !== 1) isPlural = true

    if (adventureTeam?.uiMods[word]) {
      rWord = adventureTeam.uiMods[word]

      if (rWord.includes('/')) {
        if (isPlural) rWord = rWord.split('/')[1]
        else rWord = rWord.split('/')[0]
      } else {
        if (isPlural) rWord = rWord + 's'
      }
    } else {
      if (isPlural) rWord = rWord + 's'
    }

    if (isLower) rWord = rWord.toLowerCase()
    else rWord = rWord.charAt(0).toUpperCase() + rWord.slice(1)

    return rWord
  } catch (error) {
    return word
  }
}
